import axios from "axios";
import React, { useState } from "react";
import { Box, Typography, useMediaQuery, Button } from "@mui/material";
import { API_ROUTES } from "../../constant/apiConstant";
import "./style.css";

const ContactUs = ({ contactRef }) => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const socialMediaList = [
    {
      label: "(+91) 9831131108",
      icon: "/assets/phone.svg",
    },
    {
      label: "info@purpleblock.in",
      icon: "/assets/email.svg",
    },
    {
      label: "India",
      icon: "/assets/location.svg",
    },
  ];

  const socialMedia = [
    {
      icon: "/assets/twitter.svg",
      route: "https://x.com/PurpleBlockLLP",
      name: "Twitter",
    },
    {
      icon: "/assets/instagram.svg",
      route: "https://www.instagram.com/purpleblockllp/",
      name: "Instagram",
    },
    {
      icon: "/assets/linkedin.svg",
      route: "https://www.linkedin.com/company/purple-block-llp/",
      name: "LinkedIn",
    },
  ];

  const [contactFields, setContactFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactFields({ ...contactFields, [name]: value });
  };

  const handleSocialMediaClick = (route) => {
    window.open(route, "_blank", "noopener,noreferrer");
  };

  const validateFields = () => {
    for (const key in contactFields) {
      if (contactFields[key].trim() === "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      alert("Please fill out all fields.");
      return;
    }

    try {
      const res = await axios.post(API_ROUTES.CONTACT_US, { ...contactFields });
      if (res.data) {
        setContactFields({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
        alert(res.data.message);
      }
    } catch (error) {
      alert("An error occurred: ${error.message}");
    }
  };

  return (
    <div ref={contactRef} className="contact-container">
      <div className="contact-header">
        <div className="contact-title-wrapper">
          <Box
            sx={{
              flexGrow: 1,
              height: "2px",
              backgroundColor: "#8522B9",
              maxWidth: isSmallScreen ? "30%" : "29rem", // Adjusted for small screens
            }}
          />
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            color="#8522B9"
            fontWeight="bold"
            sx={{ mx: 2, whiteSpace: "nowrap" }}
          >
            Contact Us
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              height: "2px",
              backgroundColor: "#8522B9",
              maxWidth: isSmallScreen ? "30%" : "29rem", // Adjusted for small screens
            }}
          />
        </div>
      </div>
      <div className="contact-form">
        <div className="contact-info" style={{ flexGrow: 1 }}>
          <div className="contact-info-header">
            <label>Contact Information</label>
          </div>
          <div className="contact-info-list">
            {socialMediaList.map(({ label, icon }) => (
              <div className="contact-info-item" key={label}>
                <img src={icon} className="contact-info-icon" alt={label} />
                <span>{label}</span>
              </div>
            ))}
          </div>
          <div className="social-media">
            {socialMedia.map(({ route, icon }, index) => (
              <div
                className="social-media-icon"
                key={index}
                onClick={() => handleSocialMediaClick(route)}
              >
                <img src={icon} className="social-icon" alt={route} />
              </div>
            ))}
          </div>
        </div>
        <div className="contact-fields" style={{ flexGrow: 2 }}>
          <div className="contact-field-row">
            <div className="contact-field">
              <label>First Name</label>
              <input
                name="firstName"
                value={contactFields.firstName}
                onChange={handleChange}
                placeholder="Enter first name "
              />
            </div>
            <div className="contact-field">
              <label>Last Name</label>
              <input
                name="lastName"
                value={contactFields.lastName}
                onChange={handleChange}
                placeholder="Enter last name"
              />
            </div>
          </div>
          <div className="contact-field-row">
            <div className="contact-field">
              <label>Email</label>
              <input
                name="email"
                value={contactFields.email}
                onChange={handleChange}
                placeholder="Enter email "
              />
            </div>
            <div className="contact-field">
              <label>Phone Number</label>
              <input
                name="phone"
                value={contactFields.phone}
                onChange={handleChange}
                placeholder="Enter phone number"
              />
            </div>
          </div>
          <div className="contact-field">
            <label>Subject</label>
            <input
              name="subject"
              value={contactFields.subject}
              onChange={handleChange}
              placeholder="Write your subject.. "
            />
          </div>
          <div className="contact-field message-field">
            <label>Message</label>
            <textarea
              name="message"
              value={contactFields.message}
              onChange={handleChange}
              placeholder="Write your message.. "
              rows={6}
            />
          </div>
          {isSmallScreen ? (
            <div className="contact-submit">
              <button onClick={handleSubmit}>Send Message</button>
            </div>
          ) : (
            <Button
              onClick={handleSubmit}
              variant={"contained"}
              className={"contact-submit-v2"}
              sx={{
                color: "white",
                fontFamily: "Calibri, 'Trebuchet MS', sans-serif",
                background: "#885EFE",
                position: "absolute",
                bottom: "15px",
                right: "15px",
              }}
            >
              Send Message
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
