import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './style.css'
const CarouselSlider = ({ children }) => {
    return (
      <div className="carousel-wrapper"> 
        <Carousel infiniteLoop 	showArrows={false} showStatus={false} autoPlay={true} interval='7000' showThumbs={false}>
            {children}
        </Carousel>
      </div>
    )
}

export default CarouselSlider