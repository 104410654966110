import React, { useState } from 'react'
import axios from 'axios'
import { API_ROUTES } from '../../constant/apiConstant';
import './style.css';

const Footer = () => {
  const footerItems = {
    reachUSList: [
      {
        title: 'Reach us',
        itemsList: [
          {
            label: '(+1) 510-945-8169',
            icon: '/assets/phone.svg'
          },
          {
            label: 'info@purpleblock.com',
            icon: '/assets/email.svg'
          },
          {
            label: '39934 River Road, Fort Fay 13311',
            icon: '/assets/location.svg'
          },
        ]
      }
    ],
    companyList: [
      {
        title: 'Company',
        itemsList: [
          {
            label: 'About',
          },
          {
            label: 'Contact',
          },
          {
            label: 'Blogs',
          },
        ]
      }
    ],
    LegalList: [
      {
        title: 'Legal',
        itemsList: [
          {
            label: 'Privacy Policy',
          },
          {
            label: 'Terms & Services',
          },
          {
            label: 'Terms of Use',
          },
          {
            label: 'Refund Policy',
          },
        ]
      }
    ]
  }
  const [contactFields, setContactFields] = useState({
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactFields({ ...contactFields, [name]: value });
  };

  const validateFields = () => {
    for (const key in contactFields) {
      if (contactFields[key].trim() === '') {
        return false;
      }
    }
    return true;
  };


  const handleSubmit = async () => {
    if (!validateFields()) {
      alert('Please fill out email address.');
      return;
    }

    try {
      const res = await axios.post(API_ROUTES.SUBSCRIBE_NEWSLATER, { ...contactFields });
      console.log("Response:", res);
      if (res.data) {
        setContactFields({
          email: ''
        });
        alert(res.data.message);
      }
    } catch (error) {
      console.error(error.message);
      alert(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="footer-container">
      <div className="footer-logo">
        <img src='/assets/logo.svg' className="footer-logo-img" />
        <span className="footer-logo-text">PURPLE BLOCK</span>
      </div>
      {/* <hr className="footer-divider" /> */}
      {/* <div className="footer-content">
        <div className='footer-item-container'>
          <div>
            {footerItems?.reachUSList?.map(({ title, itemsList }) => (
              <div className="footer-section">
                <label>{title}</label>
                {itemsList?.map(({ label, icon }) => (
                  <div className="footer-item">
                    <img src={icon} className="footer-icon" />
                    <span className="footer-text">{label}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div>
            {footerItems?.companyList?.map(({ title, itemsList }) => (
              <div className="footer-section1">
                <label>{title}</label>
                {itemsList?.map(({ label }) => (
                  <div>
                    <span className="footer-text">{label}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div>
          {footerItems?.LegalList?.map(({ title, itemsList }) => (
            <div className="footer-section">
              <label>{title}</label>
              {itemsList?.map(({ label }) => (
                <div>
                  <span className="footer-text">{label}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="newsletter-container">
          <label className="newsletter-label">Join Our NewsLetter</label>
          <div>
            <input
              name='email'
              value={contactFields.email}
              placeholder='Your email address'
              onChange={handleChange}
              className="newsletter-input"
            />
            <button
              className="newsletter-button"
              onClick={handleSubmit}
            >
              Subscribe
            </button>
          </div>
          <span className="newsletter-disclaimer">
            *  Will send you weekly updates for your better tool management.
          </span>
        </div>
      </div> */}
    </div>
  )
}

export default Footer;
