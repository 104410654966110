import React, { useRef } from 'react';
import Navbar from '../../layout/Navbar';
import Footer from '../../layout/Footer';
import HeroSection from '../../components/HeroSection';
import AboutUs from '../../components/AboutUs';
import OurTeam from '../../components/OurTeam';
import ContactUs from '../../components/ContactUs';
import Services from '../../components/Services';
import './style.css';

const Home = () => {
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const contactRef = useRef(null);

  const refValue = {
    aboutRefValue: aboutRef,
    serviceRefValue: serviceRef,
    contactRefValue: contactRef
  };

  return (
    <div className="home-container">
      <Navbar refValue={refValue} />
      <HeroSection />
      <AboutUs aboutRef={aboutRef} />
      <Services serviceRef={serviceRef} />
      <OurTeam />
      <ContactUs contactRef={contactRef} />
      <Footer />
    </div>
  );
};

export default Home;
