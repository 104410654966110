import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const OurTeam = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Checking for large screens

  const ourTeams = [
    {
      name: "Pujit Bhutoria",
      role: "Co-Founder",
      experience: "6+ years in Business Strategy, Growth & Finance",
      socialMedia: [
        {
          icon: LinkedInIcon,
          route: "https://www.linkedin.com/in/pujitbhutoria/",
        },
      ],
      image: "/assets/pujit1.jpeg",
    },
    {
      name: "Alpesh Saharia",
      role: "Co-Founder",
      experience: "6+ years in Data Science & Applied AI",
      socialMedia: [
        {
          icon: LinkedInIcon,
          route: "https://www.linkedin.com/in/alpesh-saharia-b168a01b2/",
        },
      ],
      image: "/assets/alpesh.png",
    },
  ];

  return (
    <Container sx={{ py: 6 }}>
      {/* Header */}
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <Box sx={{ flexGrow: 1, height: "2px", backgroundColor: "#8522B9" }} />
        <Typography
          variant={isSmallScreen ? "h5" : "h4"}
          color="#8522B9"
          fontWeight="bold"
          sx={{ mx: 2, whiteSpace: "nowrap" }}
        >
          Meet Our Team
        </Typography>
        <Box sx={{ flexGrow: 1, height: "2px", backgroundColor: "#8522B9" }} />
      </Box>

      {/* Description */}
      <Box
        textAlign="center"
        mb={6}
        px={{ xs: 2, md: 10, lg: 15 }}
        maxWidth={isLargeScreen ? "100%" : "80%"}
        mx="auto"
      >
        <Typography
          variant="body1"
          sx={{
            lineHeight: 1.3, // Adjusted line height
            fontSize: isSmallScreen ? "0.9rem" : "1rem", // Reduced font size for better fit
            maxWidth: "140%", // Ensure it doesn't exceed container
          }}
        >
          Our team synergizes the expertise of seasoned technology professionals
          and data gurus with the business acumen of our management
          professionals. Together, we deliver well-rounded, impactful solutions
          that drive success for your business.
        </Typography>
      </Box>

      {/* Team Cards */}
      <Grid container spacing={4} justifyContent="center">
        {ourTeams.map(({ name, role, experience, socialMedia, image }) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
            <Card
              sx={{
                backgroundColor: "#372540",
                borderRadius: "1rem",
                height: "92%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                boxShadow: 3,
                maxWidth: 280,
                margin: "auto",
              }}
            >
              <CardMedia
                component="img"
                image={image}
                alt={name}
                sx={{
                  height: "57%",
                  objectFit: "cover",
                  // Removed borderBottom to eliminate the purple line below the image
                }}
              />
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  p: 1.5,
                  color: "white",
                }}
              >
                <Typography variant="h6" sx={{ fontSize: "1.2rem", mb: 0.5 }}>
                  {name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 0.5,
                    minHeight: "2.5em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {experience}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#885EFE", mb: 1 }}
                >
                  {role}
                </Typography>
                <Box>
                  {socialMedia.map(({ route, icon: Icon }) => (
                    <IconButton
                      key={route}
                      href={route}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        backgroundColor: "#885EFE",
                        color: "white",
                        width: 36,
                        height: 36,
                        "&:hover": { backgroundColor: "#6d4bc7" },
                      }}
                    >
                      <Icon />
                    </IconButton>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OurTeam;
