import React, { useState, useEffect, useRef } from 'react';
import './style.css';

const Navbar = ({ refValue }) => {
  const [hoverValue, setHoverValue] = useState({
    isHover: true,
    hoverKey: null
  });

  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null); // Ref for the mobile menu container

  const navbarItems = [
    { label: 'HOME' },
    { label: 'ABOUT US', refVal: refValue?.aboutRefValue },
    { label: 'SERVICES', refVal: refValue?.serviceRefValue },
    { label: 'CONTACT', refVal: refValue?.contactRefValue }
  ];

  const handleClick = (refVal) => {
    window.scrollTo({
      top: refVal?.current?.offsetTop,
      behavior: 'smooth'
    });
    setMenuOpen(false); // Close mobile menu after click
  };

  const handleMouseOverCapture = (idx) => {
    setHoverValue({
      hoverKey: idx,
      isHover: true
    });
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  // Effect to handle clicks outside the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='navbar-container'>
      <div className='navbar-logo'>
        <img className='navbar-logo-img' src='/assets/logo.svg' alt='Logo' />
        <span className='navbar-logo-text'>PURPLE BLOCK</span>
      </div>
      <div className='navbar-items'>
        {navbarItems.map(({ label, refVal }, idx) => (
          <div
            className='navbar-item'
            onMouseOutCapture={() => setHoverValue({ isHover: false, hoverKey: null })}
            onMouseOverCapture={() => handleMouseOverCapture(idx)}
            onClick={() => handleClick(refVal)}
            key={idx}
          >
            {hoverValue.isHover && hoverValue.hoverKey === idx ? (
              <img className='navbar-icon' src='/assets/navigateArrow.svg' style={{ width: '2rem' }} alt='Navigate Arrow' />
            ) : (
              <div className='navbar-icon-container'>
                <img className='navbar-icon' style={{ width: '0.5rem' }} src='/assets/purpleCircle.svg' alt='Purple Circle' />
              </div>
            )}
            <label className='navbar-label'>{label}</label>
          </div>
        ))}
      </div>
      <div className='hamburger-menu' onClick={toggleMenu}>
        <div className='hamburger-bar'></div>
        <div className='hamburger-bar'></div>
        <div className='hamburger-bar'></div>
      </div>
      <div className={`mobile-menu ${isMenuOpen ? 'show' : ''}`} ref={menuRef}>
        {navbarItems.map(({ label, refVal }, idx) => (
          <div
            className='mobile-menu-item'
            onClick={() => handleClick(refVal)}
            key={idx}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
